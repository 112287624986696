<template>
  <div class="builder-preview" id="builder-preview">
    <template v-for="(element,index) in elements" :key="element.order">
        <div @click.stop="handle_select_el(element,index)" v-if="element.el=='el-input'" :id="'block-'+index" class="builder-preview-field" :style="{textAlign:element.position}">
            <h2 v-if="element.header.content" :style="{
                textAlign:element.header.header_position,
                fontSize:element.header.header_size+'px',
                color:element.header.header_color,
                fontStyle:element.header.header_style,
                fontWeight: element.header.header_bold,
                textDecoration:element.header.header_decoration

            }">{{element.header.content}} <span v-if="element.mandatory" style="color:red;">*</span></h2>
            <component v-model="element.data" :style="{width:element.width + '%'}" 
            :rows="element.rows" :type="element.type"  :min="element.min" :max="element.max"
            :placeholder="element.placeholder" :is="element.el"></component>

            <div v-if="indication==index" @click.stop="handle_delete(element)" class="delete-block">
                <i class="material-icons">delete</i>
            </div>
        </div>
        <div @click.stop="handle_select_el(element,index)" v-if="element.el=='el-button'" :id="'block-'+index" class="builder-preview-field" :style="{textAlign:element.position}">
            <h2 v-if="element.header">{{element.header}} <span v-if="element.mandatory" style="color:red">*</span></h2>
            <component :style="{width:element.width + '%', fontSize:element.size+'px'}" 
             :type="element.type"
             :is="element.el" >
                {{element.data}}
             </component>

            <div v-if="indication==index" @click.stop="handle_delete(element)" class="delete-block">
                <i class="material-icons">delete</i>
            </div>
        </div>
        <div @click.stop="handle_select_el(element,index)" v-if="element.type=='select'" :id="'block-'+index" class="builder-preview-field" :style="{textAlign:element.position}">
            <h2 v-if="element.header.content" :style="{
                textAlign:element.header.header_position,
                fontSize:element.header.header_size+'px',
                color:element.header.header_color,
                fontStyle:element.header.header_style,
                fontWeight: element.header.header_bold,
                textDecoration:element.header.header_decoration
            }">{{element.header.content}} <span v-if="element.mandatory" style="color:red;">*</span></h2>
            <component v-model="element.data" filterable allow-create :style="{width:element.width + '%'}" 
             :placeholder="element.placeholder"
             :is="element.el" >
                 <el-option
                    v-for="option in element.options"
                    :key="option"
                    :value="option"
                    />
             </component>
    
            <div v-if="indication==index" @click.stop="handle_delete(element)" class="delete-block">
                <i class="material-icons">delete</i>
            </div>
        </div>
        <div @click.stop="handle_select_el(element,index)" v-if="element.type=='multi_select'" :id="'block-'+index" class="builder-preview-field" :style="{textAlign:element.position}">
            <h2 v-if="element.header.content" :style="{
                textAlign:element.header.header_position,
                fontSize:element.header.header_size+'px',
                color:element.header.header_color,
                fontStyle:element.header.header_style,
                fontWeight: element.header.header_bold,
                textDecoration:element.header.header_decoration
            }">{{element.header.content}} <span v-if="element.mandatory" style="color:red;">*</span></h2>
            <component multiple filterable allow-create v-model="element.data" :style="{width:element.width + '%'}" 
             :placeholder="element.placeholder"
             :is="element.el" >
                 <el-option
                    v-for="option in element.options"
                    :key="option"
                    :value="option"
                    />
             </component>
    
            <div v-if="indication==index" @click.stop="handle_delete(element)" class="delete-block">
                <i class="material-icons">delete</i>
            </div>
        </div>
         <div @click.stop="handle_select_el(element,index)" v-if="element.el=='SignaturePad'" :id="'block-'+index" class="builder-preview-field" :style="{textAlign:element.position}">
             <h2 v-if="element.header.content" :style="{
                textAlign:element.header.header_position,
                fontSize:element.header.header_size+'px',
                color:element.header.header_color,
                fontStyle:element.header.header_style,
                fontWeight: element.header.header_bold,
                textDecoration:element.header.header_decoration
            }">{{element.header.content}} <span v-if="element.mandatory" style="color:red;">*</span></h2>
             <SignaturePad :idName="element.id_name" :sketchName="element.sketchName" :width="element.width"/>
             <div v-if="indication==index" @click.stop="handle_delete(element)" class="delete-block">
                <i class="material-icons">delete</i>
            </div>
         </div>
         <div @click.stop="handle_select_el(element,index)" v-if="element.el=='h1'" :id="'block-'+index" class="builder-preview-field">
             <h1 :style="{
                textAlign:element.header_position,
                fontSize:element.header_size+'px',
                color:element.header_color,
                fontStyle:element.header_style,
                fontWeight: element.header_bold,
                textDecoration:element.header_decoration
            }">{{element.content || 'פה אמורה להיות כותרת'}}</h1>
            <div v-if="indication==index" @click.stop="handle_delete(element)" class="delete-block">
                <i class="material-icons">delete</i>
            </div>
         </div>
         <div @click.stop="handle_select_el(element,index)" v-if="element.el=='p'" :id="'block-'+index" class="builder-preview-field" :style="{padding:`${element.p_padding_u_d}px ${element.p_padding_sides}px`}">
             <p :style="{
                textAlign:element.p_position,
                fontSize:element.p_size+'px',
                color:element.p_color,
                fontStyle:element.p_style,
                fontWeight: element.p_bold,
                textDecoration:element.p_decoration,
                whiteSpace:'pre-wrap', 
             }">{{element.content || 'פה אמורה להיות פסקה'}}</p>
            <div v-if="indication==index" @click.stop="handle_delete(element)" class="delete-block">
                <i class="material-icons">delete</i>
            </div>
         </div>
    </template>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {current_element,elements} from '../../../../../../Scripts/Forms_builder'
import SignaturePad from './Signature/SignaturePad.vue'
export default {
    components:{SignaturePad},
    props:['is_preview'],
    setup(props){
        const indication = ref(null)

        const handle_select_el = (element,index)=>{
            if(!props.is_preview){
                indication.value = index
                current_element.value = element
                console.log(current_element.value);
                document.querySelectorAll('.builder-preview-field').forEach(block=>block.style.border='unset')
                document.getElementById(`block-${index}`).style.border='1px solid red'
            }
        }

        const handle_delete = (el)=>{
            const index = elements.value.findIndex(_el=>_el.order==el.order)
            index!=-1 && elements.value.splice(index,1)
            current_element.value = null
            indication.value = null
        }

        return {
            handle_select_el,
            handle_delete,
            elements,
            current_element,
            indication,
        }
    }
}
</script>

<style scoped>
    .builder-preview{
        width: 100%;
        height: 100%;
        padding: 0 5px;
        overflow: hidden;
        overflow-y: auto;
    }
    .builder-preview-field{
        position: relative;
        width: 100%;
        height: fit-content;
        margin-top: 5px;
        cursor: pointer;
        border-radius: 5px;
    }
    .delete-block{
        position: absolute;
        width: 20px;
        height: 20px;
        bottom: 2px;
        left: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #fff;
        z-index: 10;
        background: crimson;
        border-radius: 50%;
    }
    .delete-block i{
        font-size: 13px;
    }

    .paint{
        width: 100%;
    }
</style>