
import { ref } from '@vue/reactivity';
import {alert_with_text_input,alert,alert_confirm} from './Msgs'
import {projectFirestore} from '../../../../firebase/config'

const search = ref('')
const builded_forms = ref([])
const current_element = ref(null)
const elements = ref([])
const edit_form = ref(null)


const handle_add_element = (element,type,header)=>{
    switch (element) {
        case 'el-input':
            current_element.value = {
                el:element,
                type,
                placeholder:'',
                position:'right',
                width:100,
                header:{
                    content:header,
                    header_position:'right',
                    header_size:28,
                    header_color:'black',
                    header_style:'normal',
                    header_bold:'unset',
                    header_decoration:'none'
                },
                mandatory:true,
                rows:2,
                data:'',
                min:null,
                max:null,
                order:elements.value.length==0?0:Math.max.apply(Math, elements.value.map((el)=>el.order))+1
            }
            elements.value.push(current_element.value)
            break;
        case 'el-button':
            current_element.value = {
                el:element,
                type,
                position:'right',
                width:100,
                size:15,
                data:'כפתור',
                order:elements.value.length==0?0:Math.max.apply(Math, elements.value.map((el)=>el.order))+1
            }
            elements.value.push(current_element.value)
            break;
        case 'el-select':
            if(type=='select'){
                current_element.value = {
                    el:element,
                    type,
                    placeholder:'בחירה יחידה',
                    position:'right',
                    width:100,
                    header:{
                        content:header,
                        header_position:'right',
                        header_size:28,
                        header_color:'black',
                        header_style:'normal',
                        header_bold:'unset',
                        header_decoration:'none'
                    },
                    mandatory:true,
                    multiple:false,
                    data:'',
                    options:[],
                    order:elements.value.length==0?0:Math.max.apply(Math, elements.value.map((el)=>el.order))+1
                }
            }
            if(type=='multi_select'){
                current_element.value = {
                    el:element,
                    type,
                    placeholder:'בחירה מרובה',
                    width:100,
                    header:{
                        content:header,
                        header_position:'right',
                        header_size:28,
                        header_color:'black',
                        header_style:'normal',
                        header_bold:'unset',
                        header_decoration:'none'
                    },
                    mandatory:true,
                    data:[],
                    options:[],
                    order:elements.value.length==0?0:Math.max.apply(Math, elements.value.map((el)=>el.order))+1
                }
            }
            elements.value.push(current_element.value)
            break;
        case 'SignaturePad':
            current_element.value = {
                el:element,
                type,
                width:500,
                position:'right',
                header:{
                    content:header,
                    header_position:'right',
                    header_size:28,
                    header_color:'black',
                    header_style:'normal',
                    header_bold:'unset',
                    header_decoration:'none'
                },
                mandatory:true,
                id_name:`paint-${Math.random()}`,
                sketchName:`sketchName-${Math.random()}`,
                order:elements.value.length==0?0:Math.max.apply(Math, elements.value.map((el)=>el.order))+1
            }
            console.log(current_element.value);
            elements.value.push(current_element.value)
            break;
        case 'h1':
            current_element.value = {
                el:element,
                type,
                width:100,
                content:'',
                header_position:'right',
                header_size:28,
                header_color:'black',
                header_style:'normal',
                header_bold:'unset',
                header_decoration:'none',
                order:elements.value.length==0?0:Math.max.apply(Math, elements.value.map((el)=>el.order))+1
            }
            elements.value.push(current_element.value)
            break;
        case 'p':
            current_element.value = {
                el:element,
                type,
                position:'right',
                width:100,
                content:'',
                p_position:'right',
                p_size:15,
                p_color:'black',
                p_style:'normal',
                header_bold:'unset',
                p_decoration:'none',
                p_padding_sides:0,
                p_padding_u_d:0,
                order:elements.value.length==0?0:Math.max.apply(Math, elements.value.map((el)=>el.order))+1
            }
            elements.value.push(current_element.value)
            break;
    }
}

const handle_save_form_db = async()=>{
    console.log(elements.value);
    const form_name = await alert_with_text_input('נא לרשום את שם הטופס');

    if(form_name){
        const doc = projectFirestore.collection('Applications').doc('Bfd9lA2HTBdhAJOOa40x').collection('Build_forms').doc()
        await doc.set({
            created_at:new Date(),
            form_name:form_name,
            elements:elements.value,
            uid:doc.id,
        })
        alert('success','הצלחה',
        `הטופס ${form_name} נשמר בהצלחה`)
        .then(()=>{
            current_element.value  = null
            elements.value = []
        })
      
    }


}

const get_build_form_by_name_and_uid_from_db = async()=>{ 
    const docs = await projectFirestore.collection('Applications').doc('Bfd9lA2HTBdhAJOOa40x').collection('Build_forms').get()
    return docs.docs.map(doc=>{
        return {
            form_name:doc.data().form_name,
            uid:doc.data().uid
        }
    })
}

const get_all_build_form_from_db = async()=>{
    const docs = await projectFirestore.collection('Applications').doc('Bfd9lA2HTBdhAJOOa40x').collection('Build_forms').get()
    builded_forms.value = docs.docs.map(doc=>doc.data())
}

const handle_edit_form = (form)=>{
    edit_form.value  = form
    elements.value = form.elements
}

const handle_update_form = async()=>{
    const text = await alert_with_text_input('שם הטופס',edit_form.value.form_name)
    if(text){
        edit_form.value.form_name = text
        await projectFirestore.collection('Applications').doc('Bfd9lA2HTBdhAJOOa40x').collection('Build_forms').doc(edit_form.value.uid)
        .set({
            elements:edit_form.value.elements,
            form_name:edit_form.value.form_name
        },{merge:true})
        
        alert('success','הצלחה'
        ,`הטופס ${edit_form.value.form_name} עודכן בהצלחה`)
    }
}

const handle_delete_form = (form)=>{
    alert_confirm(`למחוק את הטופס ${form.form_name}?`)
    .then(async res=>{
        if(res.isConfirmed){
            await projectFirestore.collection('Applications').doc('Bfd9lA2HTBdhAJOOa40x').collection('Build_forms')
            .doc(form.uid).delete()
            const index = builded_forms.value.findIndex(_form=>_form.uid==form.uid)
            index!=-1 && builded_forms.value.splice(index,1)
        }
    })
}

const temporary_save_data_form_in_db = async()=>{
    await projectFirestore.collection('Applications').doc('Bfd9lA2HTBdhAJOOa40x')
    .set({
        temporary:{
            form_build:elements.value
        }
    },{merge:true})
}

const reload_temporary_data_form_from_db = async()=>{
    const doc = await projectFirestore.collection('Applications').doc('Bfd9lA2HTBdhAJOOa40x').get()
    elements.value = doc.data().temporary.form_build
}
const reload_sepecific_form_data_from_db = async(form_id)=>{
    const doc = await projectFirestore.collection('Applications').doc('Bfd9lA2HTBdhAJOOa40x').collection('Build_forms')
    .doc(form_id).get()
    elements.value = doc.data().elements
}


export{
    current_element,
    elements,
    handle_add_element,
    handle_save_form_db,
    get_build_form_by_name_and_uid_from_db,
    get_all_build_form_from_db,
    builded_forms,
    edit_form,
    handle_edit_form,
    handle_update_form,
    handle_delete_form,
    search,
    temporary_save_data_form_in_db,
    reload_temporary_data_form_from_db,
    reload_sepecific_form_data_from_db
}